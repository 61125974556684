.ion-page {
  z-index: unset !important;
  contain: unset !important;
}

h1 {
  font-size: 20px;
  color: #094868;
  margin-bottom: 0;
}

p {
  margin: 0;
}

/* .globalClass_deb7 {
  position: absolute !important;
  z-index: 1 !important;
} */

jdiv[class*="globalClass_"] {
  position: absolute !important;
  z-index: 1 !important;
}

jdiv[class*="button_"][class*="_green_"] {
  background-image: none !important;
}

jdiv[class*="button_"] {
  bottom: 45px !important;
  box-shadow: none !important;
}

jdiv[class*="iconWrap_"][class*="_showLogo_"] {
  background-image: url("./media/RAFBuddy.png") !important;
  background-size: cover !important;
  bottom: 45px !important;
  box-shadow: 0 19px 38px 0 rgb(34, 36, 43, 0.3);
  border-radius: 31px;
}

jdiv[class*="messagesLabel_"] {
  background-color: #fc576b !important;
  z-index: 1;
}

jdiv[class*="popupAnim_"] {
  background-color: #fff !important;
}

.__jivoMobileButton * {
  background: none !important;
}

.full-overlay {
  z-index: 10;
  background-color: #faf9fe;
  color: #000;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
}

.full-overlay img {
  margin: auto;
}

.full-overlay video {
  position: relative;
  left: -155px;
  width: 181%;
}

/* .button_7ab6 {
  position: absolute !important;
  bottom: 5vh;
  right: 0px;
  z-index: 1;
} */

.rafIcon {
  position: absolute;
  display: none;
  bottom: 60px;
  right: 15px;
  z-index: 90;
  width: 75px;
  border-radius: 10px;
}

.rafIcon.show {
  display: block;
}

.icon {
  width: 30px;
  height: 30px;
}

.tabs-md .tab-button[aria-selected="true"] .tab-button-icon {
  color: #094868 !important;
  font-weight: 700;
}

.tabs-ios .tab-button[aria-selected="true"] .tab-button-icon {
  color: #094868 !important;
  font-weight: 700;
}

.ios.header-ios.header-collapse-none.hydrated.header-collapse-condense-inactive.header-collapse-main {
  height: 5px;
}

.Random {
  position: absolute;
  z-index: 99;
  top: 100px;
}

.wrap_7a20 {
  z-index: 9 !important;
}

.globalClass_deb7 {
  position: relative;
  z-index: 8 !important;
}
