.faq-container {
  padding: 0 25px;
}

p {
  height: 0;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

p.open {
  height: 25vh;
  margin-top: 30px;
  overflow: scroll;
}
